export const UsersForm = () => import('../../modules/users/components/Form.vue' /* webpackChunkName: "components/users-form" */).then(c => wrapFunctional(c.default || c))
export const StoresTable = () => import('../../modules/stores/components/Table.vue' /* webpackChunkName: "components/stores-table" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesEditorsCodeValidator = () => import('../../modules/landingPages/components/editors/CodeValidator.vue' /* webpackChunkName: "components/landing-pages-editors-code-validator" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesEditorsCodemirror = () => import('../../modules/landingPages/components/editors/Codemirror.vue' /* webpackChunkName: "components/landing-pages-editors-codemirror" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesEditorsNotice = () => import('../../modules/landingPages/components/editors/Notice.vue' /* webpackChunkName: "components/landing-pages-editors-notice" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesEditorsPlaceholders = () => import('../../modules/landingPages/components/editors/Placeholders.vue' /* webpackChunkName: "components/landing-pages-editors-placeholders" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesEditorsPreview = () => import('../../modules/landingPages/components/editors/Preview.vue' /* webpackChunkName: "components/landing-pages-editors-preview" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesEditorsTiny = () => import('../../modules/landingPages/components/editors/Tiny.vue' /* webpackChunkName: "components/landing-pages-editors-tiny" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsCode = () => import('../../modules/landingPages/components/cards/Code.vue' /* webpackChunkName: "components/landing-pages-cards-code" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsConfig = () => import('../../modules/landingPages/components/cards/Config.vue' /* webpackChunkName: "components/landing-pages-cards-config" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsDeployments = () => import('../../modules/landingPages/components/cards/Deployments.vue' /* webpackChunkName: "components/landing-pages-cards-deployments" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsMeta = () => import('../../modules/landingPages/components/cards/Meta.vue' /* webpackChunkName: "components/landing-pages-cards-meta" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsRevisions = () => import('../../modules/landingPages/components/cards/Revisions.vue' /* webpackChunkName: "components/landing-pages-cards-revisions" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsTemplates = () => import('../../modules/landingPages/components/cards/Templates.vue' /* webpackChunkName: "components/landing-pages-cards-templates" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesCardsVideo = () => import('../../modules/landingPages/components/cards/Video.vue' /* webpackChunkName: "components/landing-pages-cards-video" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesLayoutsCreate = () => import('../../modules/landingPages/components/layouts/Create.vue' /* webpackChunkName: "components/landing-pages-layouts-create" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesLayoutsEdit = () => import('../../modules/landingPages/components/layouts/Edit.vue' /* webpackChunkName: "components/landing-pages-layouts-edit" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesLayoutsList = () => import('../../modules/landingPages/components/layouts/List.vue' /* webpackChunkName: "components/landing-pages-layouts-list" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesAppointmentBooking = () => import('../../modules/landingPages/components/types/AppointmentBooking.vue' /* webpackChunkName: "components/landing-pages-types-appointment-booking" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesBase = () => import('../../modules/landingPages/components/types/Base.vue' /* webpackChunkName: "components/landing-pages-types-base" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesCrossSell = () => import('../../modules/landingPages/components/types/CrossSell.vue' /* webpackChunkName: "components/landing-pages-types-cross-sell" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesEmailSignup = () => import('../../modules/landingPages/components/types/EmailSignup.vue' /* webpackChunkName: "components/landing-pages-types-email-signup" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesFinalOrderConfirmation = () => import('../../modules/landingPages/components/types/FinalOrderConfirmation.vue' /* webpackChunkName: "components/landing-pages-types-final-order-confirmation" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesFreeSignup = () => import('../../modules/landingPages/components/types/FreeSignup.vue' /* webpackChunkName: "components/landing-pages-types-free-signup" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesMultiOffer = () => import('../../modules/landingPages/components/types/MultiOffer.vue' /* webpackChunkName: "components/landing-pages-types-multi-offer" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesSepaEnhancement = () => import('../../modules/landingPages/components/types/SepaEnhancement.vue' /* webpackChunkName: "components/landing-pages-types-sepa-enhancement" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesUpsell = () => import('../../modules/landingPages/components/types/Upsell.vue' /* webpackChunkName: "components/landing-pages-types-upsell" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesVideo = () => import('../../modules/landingPages/components/types/Video.vue' /* webpackChunkName: "components/landing-pages-types-video" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesTypesWebinarRegistration = () => import('../../modules/landingPages/components/types/WebinarRegistration.vue' /* webpackChunkName: "components/landing-pages-types-webinar-registration" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormCodeUpload = () => import('../../modules/landingPages/components/form/CodeUpload.vue' /* webpackChunkName: "components/landing-pages-form-code-upload" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormCodeUploadErrors = () => import('../../modules/landingPages/components/form/CodeUploadErrors.vue' /* webpackChunkName: "components/landing-pages-form-code-upload-errors" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogCodeUpload = () => import('../../modules/landingPages/components/form/DialogCodeUpload.vue' /* webpackChunkName: "components/landing-pages-form-dialog-code-upload" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogConfirmSlug = () => import('../../modules/landingPages/components/form/DialogConfirmSlug.vue' /* webpackChunkName: "components/landing-pages-form-dialog-confirm-slug" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogCopy = () => import('../../modules/landingPages/components/form/DialogCopy.vue' /* webpackChunkName: "components/landing-pages-form-dialog-copy" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogDeactivate = () => import('../../modules/landingPages/components/form/DialogDeactivate.vue' /* webpackChunkName: "components/landing-pages-form-dialog-deactivate" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogDelete = () => import('../../modules/landingPages/components/form/DialogDelete.vue' /* webpackChunkName: "components/landing-pages-form-dialog-delete" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogDownloadCsv = () => import('../../modules/landingPages/components/form/DialogDownloadCsv.vue' /* webpackChunkName: "components/landing-pages-form-dialog-download-csv" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogHints = () => import('../../modules/landingPages/components/form/DialogHints.vue' /* webpackChunkName: "components/landing-pages-form-dialog-hints" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogSlug = () => import('../../modules/landingPages/components/form/DialogSlug.vue' /* webpackChunkName: "components/landing-pages-form-dialog-slug" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormDialogTitle = () => import('../../modules/landingPages/components/form/DialogTitle.vue' /* webpackChunkName: "components/landing-pages-form-dialog-title" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormGroupCampaigns = () => import('../../modules/landingPages/components/form/GroupCampaigns.vue' /* webpackChunkName: "components/landing-pages-form-group-campaigns" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormSelectOperator = () => import('../../modules/landingPages/components/form/SelectOperator.vue' /* webpackChunkName: "components/landing-pages-form-select-operator" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormSelectProduct = () => import('../../modules/landingPages/components/form/SelectProduct.vue' /* webpackChunkName: "components/landing-pages-form-select-product" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormSelectStatus = () => import('../../modules/landingPages/components/form/SelectStatus.vue' /* webpackChunkName: "components/landing-pages-form-select-status" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormSelectTarget = () => import('../../modules/landingPages/components/form/SelectTarget.vue' /* webpackChunkName: "components/landing-pages-form-select-target" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormSelectTargetName = () => import('../../modules/landingPages/components/form/SelectTargetName.vue' /* webpackChunkName: "components/landing-pages-form-select-target-name" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormTextCampaign = () => import('../../modules/landingPages/components/form/TextCampaign.vue' /* webpackChunkName: "components/landing-pages-form-text-campaign" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormTextETerminUrl = () => import('../../modules/landingPages/components/form/TextETerminUrl.vue' /* webpackChunkName: "components/landing-pages-form-text-e-termin-url" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormTextId = () => import('../../modules/landingPages/components/form/TextId.vue' /* webpackChunkName: "components/landing-pages-form-text-id" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormTextRedirectUrl = () => import('../../modules/landingPages/components/form/TextRedirectUrl.vue' /* webpackChunkName: "components/landing-pages-form-text-redirect-url" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormTextSlug = () => import('../../modules/landingPages/components/form/TextSlug.vue' /* webpackChunkName: "components/landing-pages-form-text-slug" */).then(c => wrapFunctional(c.default || c))
export const LandingPagesFormTextTitle = () => import('../../modules/landingPages/components/form/TextTitle.vue' /* webpackChunkName: "components/landing-pages-form-text-title" */).then(c => wrapFunctional(c.default || c))
export const CredentialsForm = () => import('../../modules/credentials/components/Form.vue' /* webpackChunkName: "components/credentials-form" */).then(c => wrapFunctional(c.default || c))
export const CredentialsTable = () => import('../../modules/credentials/components/Table.vue' /* webpackChunkName: "components/credentials-table" */).then(c => wrapFunctional(c.default || c))
export const FileManagerAssets = () => import('../../modules/fileManager/components/Assets.vue' /* webpackChunkName: "components/file-manager-assets" */).then(c => wrapFunctional(c.default || c))
export const FileManagerImageUpload = () => import('../../modules/fileManager/components/ImageUpload.vue' /* webpackChunkName: "components/file-manager-image-upload" */).then(c => wrapFunctional(c.default || c))
export const TenantsForm = () => import('../../modules/tenants/components/Form.vue' /* webpackChunkName: "components/tenants-form" */).then(c => wrapFunctional(c.default || c))
export const TenantsLayoutsDetails = () => import('../../modules/tenants/components/layouts/Details.vue' /* webpackChunkName: "components/tenants-layouts-details" */).then(c => wrapFunctional(c.default || c))
export const TenantsLayoutsList = () => import('../../modules/tenants/components/layouts/List.vue' /* webpackChunkName: "components/tenants-layouts-list" */).then(c => wrapFunctional(c.default || c))
export const AppDialog = () => import('../../components/AppDialog.vue' /* webpackChunkName: "components/app-dialog" */).then(c => wrapFunctional(c.default || c))
export const AppDialogDelete = () => import('../../components/AppDialogDelete.vue' /* webpackChunkName: "components/app-dialog-delete" */).then(c => wrapFunctional(c.default || c))
export const AppDialogRestore = () => import('../../components/AppDialogRestore.vue' /* webpackChunkName: "components/app-dialog-restore" */).then(c => wrapFunctional(c.default || c))
export const AppTable = () => import('../../components/AppTable.vue' /* webpackChunkName: "components/app-table" */).then(c => wrapFunctional(c.default || c))
export const AppTableActions = () => import('../../components/AppTableActions.vue' /* webpackChunkName: "components/app-table-actions" */).then(c => wrapFunctional(c.default || c))
export const BottomBar = () => import('../../components/BottomBar.vue' /* webpackChunkName: "components/bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const BtnClipboardCopy = () => import('../../components/BtnClipboardCopy.vue' /* webpackChunkName: "components/btn-clipboard-copy" */).then(c => wrapFunctional(c.default || c))
export const ElementListHeader = () => import('../../components/ElementListHeader.vue' /* webpackChunkName: "components/element-list-header" */).then(c => wrapFunctional(c.default || c))
export const FileBrowser = () => import('../../components/FileBrowser.vue' /* webpackChunkName: "components/file-browser" */).then(c => wrapFunctional(c.default || c))
export const FolderStructure = () => import('../../components/FolderStructure.vue' /* webpackChunkName: "components/folder-structure" */).then(c => wrapFunctional(c.default || c))
export const IconList = () => import('../../components/IconList.vue' /* webpackChunkName: "components/icon-list" */).then(c => wrapFunctional(c.default || c))
export const Iframe = () => import('../../components/Iframe.vue' /* webpackChunkName: "components/iframe" */).then(c => wrapFunctional(c.default || c))
export const InputColorpicker = () => import('../../components/InputColorpicker.vue' /* webpackChunkName: "components/input-colorpicker" */).then(c => wrapFunctional(c.default || c))
export const NavBar = () => import('../../components/NavBar.vue' /* webpackChunkName: "components/nav-bar" */).then(c => wrapFunctional(c.default || c))
export const VuetifyLogo = () => import('../../components/VuetifyLogo.vue' /* webpackChunkName: "components/vuetify-logo" */).then(c => wrapFunctional(c.default || c))
export const SystemAuthDialog = () => import('../../components/System/AuthDialog.vue' /* webpackChunkName: "components/system-auth-dialog" */).then(c => wrapFunctional(c.default || c))
export const SystemLoadingOverlay = () => import('../../components/System/LoadingOverlay.vue' /* webpackChunkName: "components/system-loading-overlay" */).then(c => wrapFunctional(c.default || c))
export const SystemLogin = () => import('../../components/System/Login.vue' /* webpackChunkName: "components/system-login" */).then(c => wrapFunctional(c.default || c))
export const SystemPasswordForgotten = () => import('../../components/System/PasswordForgotten.vue' /* webpackChunkName: "components/system-password-forgotten" */).then(c => wrapFunctional(c.default || c))
export const SystemPasswordReset = () => import('../../components/System/PasswordReset.vue' /* webpackChunkName: "components/system-password-reset" */).then(c => wrapFunctional(c.default || c))
export const SystemPasswordSet = () => import('../../components/System/PasswordSet.vue' /* webpackChunkName: "components/system-password-set" */).then(c => wrapFunctional(c.default || c))
export const TablesComboBox = () => import('../../components/Tables/ComboBox.vue' /* webpackChunkName: "components/tables-combo-box" */).then(c => wrapFunctional(c.default || c))
export const TablesDateRangeField = () => import('../../components/Tables/DateRangeField.vue' /* webpackChunkName: "components/tables-date-range-field" */).then(c => wrapFunctional(c.default || c))
export const TablesSearchField = () => import('../../components/Tables/SearchField.vue' /* webpackChunkName: "components/tables-search-field" */).then(c => wrapFunctional(c.default || c))
export const TablesSelectField = () => import('../../components/Tables/SelectField.vue' /* webpackChunkName: "components/tables-select-field" */).then(c => wrapFunctional(c.default || c))
export const TablesTextElement = () => import('../../components/Tables/TextElement.vue' /* webpackChunkName: "components/tables-text-element" */).then(c => wrapFunctional(c.default || c))
export const BaseAction = () => import('../../components/base/Action.vue' /* webpackChunkName: "components/base-action" */).then(c => wrapFunctional(c.default || c))
export const BaseCard = () => import('../../components/base/Card.vue' /* webpackChunkName: "components/base-card" */).then(c => wrapFunctional(c.default || c))
export const BaseDialog = () => import('../../components/base/Dialog.vue' /* webpackChunkName: "components/base-dialog" */).then(c => wrapFunctional(c.default || c))
export const BaseForm = () => import('../../components/base/Form.vue' /* webpackChunkName: "components/base-form" */).then(c => wrapFunctional(c.default || c))
export const BaseMenu = () => import('../../components/base/Menu.vue' /* webpackChunkName: "components/base-menu" */).then(c => wrapFunctional(c.default || c))
export const BaseProgress = () => import('../../components/base/Progress.vue' /* webpackChunkName: "components/base-progress" */).then(c => wrapFunctional(c.default || c))
export const BaseSelect = () => import('../../components/base/Select.vue' /* webpackChunkName: "components/base-select" */).then(c => wrapFunctional(c.default || c))
export const BaseTimestamp = () => import('../../components/base/Timestamp.vue' /* webpackChunkName: "components/base-timestamp" */).then(c => wrapFunctional(c.default || c))
export const DialogConfirmAbort = () => import('../../components/dialog/ConfirmAbort.vue' /* webpackChunkName: "components/dialog-confirm-abort" */).then(c => wrapFunctional(c.default || c))
export const DialogConfirmOverwrite = () => import('../../components/dialog/ConfirmOverwrite.vue' /* webpackChunkName: "components/dialog-confirm-overwrite" */).then(c => wrapFunctional(c.default || c))
export const HelperQueryId = () => import('../../components/helper/QueryId.vue' /* webpackChunkName: "components/helper-query-id" */).then(c => wrapFunctional(c.default || c))
export const LayoutBottomBar = () => import('../../components/layout/BottomBar.vue' /* webpackChunkName: "components/layout-bottom-bar" */).then(c => wrapFunctional(c.default || c))
export const LayoutEditor = () => import('../../components/layout/Editor.vue' /* webpackChunkName: "components/layout-editor" */).then(c => wrapFunctional(c.default || c))
export const LayoutIndexDetails = () => import('../../components/layout/IndexDetails.vue' /* webpackChunkName: "components/layout-index-details" */).then(c => wrapFunctional(c.default || c))
export const LayoutTable = () => import('../../components/layout/Table.vue' /* webpackChunkName: "components/layout-table" */).then(c => wrapFunctional(c.default || c))
export const MenuAdmin = () => import('../../components/menu/Admin.vue' /* webpackChunkName: "components/menu-admin" */).then(c => wrapFunctional(c.default || c))
export const MenuLanguage = () => import('../../components/menu/Language.vue' /* webpackChunkName: "components/menu-language" */).then(c => wrapFunctional(c.default || c))
export const MenuTenant = () => import('../../components/menu/Tenant.vue' /* webpackChunkName: "components/menu-tenant" */).then(c => wrapFunctional(c.default || c))
export const MenuUser = () => import('../../components/menu/User.vue' /* webpackChunkName: "components/menu-user" */).then(c => wrapFunctional(c.default || c))
export const BaseButtonClose = () => import('../../components/base/button/Close.vue' /* webpackChunkName: "components/base-button-close" */).then(c => wrapFunctional(c.default || c))

// nuxt/nuxt.js#8607
function wrapFunctional(options) {
  if (!options || !options.functional) {
    return options
  }

  const propKeys = Array.isArray(options.props) ? options.props : Object.keys(options.props || {})

  return {
    render(h) {
      const attrs = {}
      const props = {}

      for (const key in this.$attrs) {
        if (propKeys.includes(key)) {
          props[key] = this.$attrs[key]
        } else {
          attrs[key] = this.$attrs[key]
        }
      }

      return h(options, {
        on: this.$listeners,
        attrs,
        props,
        scopedSlots: this.$scopedSlots,
      }, this.$slots.default)
    }
  }
}

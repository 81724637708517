import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _14115536 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _6247eb82 = () => interopDefault(import('../pages/auth/forgotten.vue' /* webpackChunkName: "pages/auth/forgotten" */))
const _77eb228e = () => interopDefault(import('../pages/auth/login.vue' /* webpackChunkName: "pages/auth/login" */))
const _5705ed74 = () => interopDefault(import('../pages/auth/reset.vue' /* webpackChunkName: "pages/auth/reset" */))
const _1a091ac7 = () => interopDefault(import('../pages/auth/welcome.vue' /* webpackChunkName: "pages/auth/welcome" */))
const _39916667 = () => interopDefault(import('../modules/users/pages/index.vue' /* webpackChunkName: "" */))
const _204fb137 = () => interopDefault(import('../modules/users/pages/create.vue' /* webpackChunkName: "" */))
const _1040d405 = () => interopDefault(import('../modules/users/pages/edit.vue' /* webpackChunkName: "" */))
const _3c81765a = () => interopDefault(import('../modules/stores/pages/index.vue' /* webpackChunkName: "" */))
const _06310fce = () => interopDefault(import('../modules/stores/pages/edit.vue' /* webpackChunkName: "" */))
const _4f91464e = () => interopDefault(import('../modules/landingPages/pages/index.vue' /* webpackChunkName: "" */))
const _6b6863a0 = () => interopDefault(import('../modules/landingPages/pages/create.vue' /* webpackChunkName: "" */))
const _3707ad80 = () => interopDefault(import('../modules/landingPages/pages/create/base.vue' /* webpackChunkName: "" */))
const _965c438c = () => interopDefault(import('../modules/landingPages/pages/create/final-order-confirmation.vue' /* webpackChunkName: "" */))
const _47d519a4 = () => interopDefault(import('../modules/landingPages/pages/create/cross-sell.vue' /* webpackChunkName: "" */))
const _36aea1b1 = () => interopDefault(import('../modules/landingPages/pages/create/sepa-enhancement.vue' /* webpackChunkName: "" */))
const _63307148 = () => interopDefault(import('../modules/landingPages/pages/create/upsell.vue' /* webpackChunkName: "" */))
const _6845dfdc = () => interopDefault(import('../modules/landingPages/pages/create/video.vue' /* webpackChunkName: "" */))
const _79711c18 = () => interopDefault(import('../modules/landingPages/pages/create/email-signup.vue' /* webpackChunkName: "" */))
const _c4cda12a = () => interopDefault(import('../modules/landingPages/pages/create/webinar-registration.vue' /* webpackChunkName: "" */))
const _e2bd358c = () => interopDefault(import('../modules/landingPages/pages/create/free-signup.vue' /* webpackChunkName: "" */))
const _0b13b6e9 = () => interopDefault(import('../modules/landingPages/pages/create/multi-offer.vue' /* webpackChunkName: "" */))
const _576f1068 = () => interopDefault(import('../modules/landingPages/pages/create/appointment-booking.vue' /* webpackChunkName: "" */))
const _38ab2c1b = () => interopDefault(import('../modules/credentials/pages/index.vue' /* webpackChunkName: "" */))
const _c51d63ae = () => interopDefault(import('../modules/credentials/pages/new.vue' /* webpackChunkName: "" */))
const _20bd87d1 = () => interopDefault(import('../modules/credentials/pages/edit.vue' /* webpackChunkName: "" */))
const _3c900db0 = () => interopDefault(import('../modules/fileManager/pages/index.vue' /* webpackChunkName: "" */))
const _260b08b0 = () => interopDefault(import('../modules/tenants/pages/index.vue' /* webpackChunkName: "" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _14115536,
    name: "index___en"
  }, {
    path: "/auth/forgotten",
    component: _6247eb82,
    name: "auth-forgotten___de"
  }, {
    path: "/auth/login",
    component: _77eb228e,
    name: "auth-login___de"
  }, {
    path: "/auth/reset",
    component: _5705ed74,
    name: "auth-reset___de"
  }, {
    path: "/auth/welcome",
    component: _1a091ac7,
    name: "auth-welcome___de"
  }, {
    path: "/en/auth/forgotten",
    component: _6247eb82,
    name: "auth-forgotten___en"
  }, {
    path: "/en/auth/login",
    component: _77eb228e,
    name: "auth-login___en"
  }, {
    path: "/en/auth/reset",
    component: _5705ed74,
    name: "auth-reset___en"
  }, {
    path: "/en/auth/welcome",
    component: _1a091ac7,
    name: "auth-welcome___en"
  }, {
    path: "/",
    component: _14115536,
    name: "index___de"
  }, {
    path: "/users",
    component: _39916667,
    name: "users___de"
  }, {
    path: "/en/users",
    component: _39916667,
    name: "users___en"
  }, {
    path: "/users/create",
    component: _204fb137,
    name: "users-create___de"
  }, {
    path: "/en/users/create",
    component: _204fb137,
    name: "users-create___en"
  }, {
    path: "/users/edit",
    component: _1040d405,
    name: "users-edit___de"
  }, {
    path: "/en/users/edit",
    component: _1040d405,
    name: "users-edit___en"
  }, {
    path: "/stores",
    component: _3c81765a,
    name: "stores___de"
  }, {
    path: "/en/stores",
    component: _3c81765a,
    name: "stores___en"
  }, {
    path: "/stores/edit",
    component: _06310fce,
    name: "stores-edit___de"
  }, {
    path: "/en/stores/edit",
    component: _06310fce,
    name: "stores-edit___en"
  }, {
    path: "/landingpages",
    component: _4f91464e,
    name: "landingpages___de"
  }, {
    path: "/en/landingpages",
    component: _4f91464e,
    name: "landingpages___en"
  }, {
    path: "/landingpages/create",
    component: _6b6863a0,
    name: "landingpages-create___de"
  }, {
    path: "/en/landingpages/create",
    component: _6b6863a0,
    name: "landingpages-create___en"
  }, {
    path: "/landingpages/create/base",
    component: _3707ad80,
    name: "landingpages-create-base___de"
  }, {
    path: "/en/landingpages/create/base",
    component: _3707ad80,
    name: "landingpages-create-base___en"
  }, {
    path: "/landingpages/create/final-order-confirmation",
    component: _965c438c,
    name: "landingpages-create-final-order-confirmation___de"
  }, {
    path: "/en/landingpages/create/final-order-confirmation",
    component: _965c438c,
    name: "landingpages-create-final-order-confirmation___en"
  }, {
    path: "/landingpages/create/cross-sell",
    component: _47d519a4,
    name: "landingpages-create-cross-sell___de"
  }, {
    path: "/en/landingpages/create/cross-sell",
    component: _47d519a4,
    name: "landingpages-create-cross-sell___en"
  }, {
    path: "/landingpages/create/sepa-enhancement",
    component: _36aea1b1,
    name: "landingpages-create-sepa-enhancement___de"
  }, {
    path: "/en/landingpages/create/sepa-enhancement",
    component: _36aea1b1,
    name: "landingpages-create-sepa-enhancement___en"
  }, {
    path: "/landingpages/create/upsell",
    component: _63307148,
    name: "landingpages-create-upsell___de"
  }, {
    path: "/en/landingpages/create/upsell",
    component: _63307148,
    name: "landingpages-create-upsell___en"
  }, {
    path: "/landingpages/create/video",
    component: _6845dfdc,
    name: "landingpages-create-video___de"
  }, {
    path: "/en/landingpages/create/video",
    component: _6845dfdc,
    name: "landingpages-create-video___en"
  }, {
    path: "/landingpages/create/email-signup",
    component: _79711c18,
    name: "landingpages-create-email-signup___de"
  }, {
    path: "/en/landingpages/create/email-signup",
    component: _79711c18,
    name: "landingpages-create-email-signup___en"
  }, {
    path: "/landingpages/create/webinar-registration",
    component: _c4cda12a,
    name: "landingpages-create-webinar-registration___de"
  }, {
    path: "/en/landingpages/create/webinar-registration",
    component: _c4cda12a,
    name: "landingpages-create-webinar-registration___en"
  }, {
    path: "/landingpages/create/free-signup",
    component: _e2bd358c,
    name: "landingpages-create-free-signup___de"
  }, {
    path: "/en/landingpages/create/free-signup",
    component: _e2bd358c,
    name: "landingpages-create-free-signup___en"
  }, {
    path: "/landingpages/create/multi-offer",
    component: _0b13b6e9,
    name: "landingpages-create-multi-offer___de"
  }, {
    path: "/en/landingpages/create/multi-offer",
    component: _0b13b6e9,
    name: "landingpages-create-multi-offer___en"
  }, {
    path: "/landingpages/create/appointment-booking",
    component: _576f1068,
    name: "landingpages-create-appointment-booking___de"
  }, {
    path: "/en/landingpages/create/appointment-booking",
    component: _576f1068,
    name: "landingpages-create-appointment-booking___en"
  }, {
    path: "/credentials",
    component: _38ab2c1b,
    name: "credentials___de"
  }, {
    path: "/en/credentials",
    component: _38ab2c1b,
    name: "credentials___en"
  }, {
    path: "/credentials/new",
    component: _c51d63ae,
    name: "credentials-new___de"
  }, {
    path: "/en/credentials/new",
    component: _c51d63ae,
    name: "credentials-new___en"
  }, {
    path: "/credentials/edit",
    component: _20bd87d1,
    name: "credentials-edit___de"
  }, {
    path: "/en/credentials/edit",
    component: _20bd87d1,
    name: "credentials-edit___en"
  }, {
    path: "/filemanager",
    component: _3c900db0,
    name: "filemanager___de"
  }, {
    path: "/en/filemanager",
    component: _3c900db0,
    name: "filemanager___en"
  }, {
    path: "/tenants",
    component: _260b08b0,
    name: "tenants___de"
  }, {
    path: "/en/tenants",
    component: _260b08b0,
    name: "tenants___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
